import React from 'react'
import './activityWrapper.sass'
import { ActivityTheme } from 'declare/theme'

interface IActivityWrapper {
  children: JSX.Element
  colorTheme?: ActivityTheme
}

const colorThemeMapping = {
  bluePrimary: {
    styleWrapper: {
      background: 'rgba(48,2,109,.85)',
      border: '1px solid rgba(33,86,209,.85)'
    }
  },
  redPrimary: {
    styleWrapper: {
      background: '#300',
      border: '1px solid #feef9f'
    }
  },
  // 特斯拉
  teszlaPrimary: {
    styleWrapper: {
      background: '#843307',
      border: '1px solid #5B2304'
    }
  },
  // 粽夏
  zongxiaPrimary: {
    styleWrapper: {
      background: '#22501D',
      border: '1px solid #000'
    }
  },
  // 中秋
  moonPrimary: {
    styleWrapper: {
      background: '#3A0000',
      border: '1px solid #970D0D'
    }
  },
  // 2024 新年
  newYearPrimary: {
    styleWrapper: {
      background: '#330000',
      border: '1px solid #D12121'
    }
  },
  // 2024 中秋
  moon2024Primary: {
    styleWrapper: {
      background: '#010032',
      border: '1px solid #1309e1'
    }
  },
  // 2025 新年
  newYear2025Primary: {
    styleWrapper: {
      background: '#330000',
      border: '1px solid #330000'
    }
  }
}

function ActivityWrapper(props: IActivityWrapper) {
  const { children, colorTheme } = props

  return (
    <div className='activity-content-root'>
      <div className='wrapper' style={colorThemeMapping[colorTheme]?.styleWrapper}>
        {children}
      </div>
    </div>
  )
}

export default ActivityWrapper
