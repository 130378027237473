import React from 'react'
import imgQRCode from 'images/common/qrCode_pc_01.png'
import imgLOGO from 'images/common/logo_pc_01.png'
import imgFB from 'images/navBar/bt_facebook_pc_01.png'
import imgLINE from 'images/navBar/bt_line_pc_01.png'
import { OFFICIAL_WEBSITE, LINE, FACEBOOK } from 'constants/link'
import styles from './downloadGame.module.sass'
import { ActivityTheme } from 'declare/theme'

interface IDownloadGame {
  colorTheme?: ActivityTheme
  zIndex?: number
}

const colorThemeMapping = {
  bluePrimary: {
    styleToolBarBorder: {
      background:
        'transparent linear-gradient(180deg,rgba(0,25,249,.8),rgba(0,255,238,.8) 50%,rgba(0,25,249,.8)) 0 0 no-repeat padding-box',
      border: '1px solid rgba(0,255,238,.8)'
    },
    styleToolBar: {
      background: '#63006ecc',
      border: '1px solid rgba(0,255,238,.8)'
    },
    styleDivideLine: {
      backgroundColor: 'rgba(0,255,238,.8)'
    }
  },
  redPrimary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg,#f2aa0c,#684801) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#f60111',
      border: '1px solid #ff4f48'
    },
    styleDivideLine: {
      backgroundColor: '#ffde9e'
    }
  },
  // 特斯拉
  teszlaPrimary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg, #c8ac3a, #805c0d) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#7a360a',
      border: '1px solid #5B2304'
    },
    styleDivideLine: {
      backgroundColor: '#e2c703'
    }
  },
  // 粽夏
  zongxiaPrimary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg, #34AF26, #3BDD1B) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#218710',
      border: '1px solid transparent'
    },
    styleDivideLine: {
      backgroundColor: '#B5AD22'
    }
  },
  // 中秋
  moonPrimary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg, #CF8D1B, #7B5002) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#EC1C12',
      border: '1px solid transparent'
    },
    styleDivideLine: {
      backgroundColor: '#F8C004'
    }
  },
  // 2024 新年
  newYearPrimary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg, #1B50BF, #1B50C1) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#3E166F',
      border: '1px solid #37AECF'
    },
    styleDivideLine: {
      backgroundColor: '#37AECF'
    }
  },
  // 2024 中秋
  moon2024Primary: {
    styleToolBarBorder: {
      background: 'transparent linear-gradient(180deg, #1B50BF, #1B50C1) 0 0 no-repeat padding-box'
    },
    styleToolBar: {
      background: '#010032',
      border: '1px solid #1309e1'
    },
    styleDivideLine: {
      backgroundColor: '#010032',
      border: '1px solid #1309e1'
    }
  },
  // 2025 新年
  newYear2025Primary: {
    styleToolBarBorder: {
      background: '#007B83'
    },
    styleToolBar: {
      background: '#c10f05',
      border: '1px solid #52D1FF'
    },
    styleDivideLine: {
      backgroundColor: '#52D1FF'
    }
  }
}

function DownloadGame(props: IDownloadGame) {
  const { colorTheme, zIndex } = props

  return (
    <aside
      className={styles.root}
      style={{
        zIndex
      }}
    >
      <div
        className={styles.toolBoxBorder}
        style={colorThemeMapping[colorTheme]?.styleToolBarBorder}
      >
        <div className={styles.toolBox} style={colorThemeMapping[colorTheme]?.styleToolBar}>
          <div className={styles.toolIcon}>
            <a href={OFFICIAL_WEBSITE} title='聚寶Online官網' target='_self'>
              <img src={imgLOGO} alt='聚寶OnlineLOGO' />
            </a>
          </div>
          <span className={styles.title}>掃描下載遊戲</span>
          <div className={styles.qrCodeContainer}>
            <div className={styles.qrCode}>
              <div className='image_obj'>
                <img className={styles.QRcode} src={imgQRCode} alt='聚寶Online下載遊戲QRCode' />
              </div>
            </div>
          </div>
          <hr className={styles.hr} style={colorThemeMapping[colorTheme]?.styleDivideLine} />
          <p>
            <a href={LINE} title='聚寶Online官網帳號' target='_blank' rel='noreferrer'>
              <img src={imgLINE} alt='官網帳號' />
            </a>
          </p>
          <p>
            <a href={FACEBOOK} title='聚寶Online粉絲專頁' target='_blank' rel='noreferrer'>
              <img src={imgFB} alt='粉絲專頁' />
            </a>
          </p>
        </div>
      </div>
    </aside>
  )
}

export default DownloadGame
