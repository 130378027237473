import React from 'react'
import ActivityDate from 'views/activity250113/images/title/t1.png'
import ActivityMethod from 'views/activity250113/images/title/t2.png'
import ActivityDesignated from 'views/activity250113/images/title/t4.png'
import ActivityAward from 'views/activity250113/images/title/t3.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>獲取水滴：2025/1/27(一) 00:00~2025/2/2(日) 23:59</span>
        <br />
        <span>兌換獎勵：活動開始~ 2025/2/5(三) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>※ 成長值加碼+30%時段：</span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          活動期間每天三時段：凌晨2:00~4:00、中午12:00~14:00、晚上20:00~22:00點！
        </span>
        <br />
        <span>把握時段進行澆水，可以大幅提高福樹升級效率！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>所有「獨家老虎機」、「特殊老虎機」。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間內於所有老虎機遊玩，累積押碼達標可獲得1個水滴。使用水滴兌換澆水器後，幫福樹澆水累積成長值進行升級。福樹等級越高，採收時可獲得越稀有的道具喔！
        </span>
        <br />
        <span>若想蒐集更多水滴，也可前往活動頁面購買【開運水滴賀禮】，內含額外加贈的水滴！</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#f5e61c' }}>
          ※ 使用越大的澆水器澆水，有機會消耗越少水滴，產生更高的成長值！
        </span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 需將福樹升級至2等以上才可進行採收。 </span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 福樹等級將於每次採收獎勵後重置為等級1。 </span>
        <br />
        <span style={{ color: '#999' }}>
          範例：小寶於福樹等級24時，採收獎勵並獲得貴賓廳7星道具後，福樹等級會重置為等級1。
        </span>
        <br />
        <span style={{ color: '#f5e61c' }}>※ 採收獲得的道具，僅可於獨家老虎機中使用。</span>
        <br />
        <span style={{ color: '#f5e61c' }}>
          ※ 【開運水滴賀禮】購買相關內容請見遊戲內活動介面之說明。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityAward}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityAward}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>每次採收可獲得獎勵必定為道具，包含：</span>
        <span style={{ color: '#f5e61c' }}>鴻運卷軸、福運卷軸、吉運卷軸、招財樹</span>
        <span>，可依據福樹等級獲得指定星級的稀有道具一個。</span>
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-7 table-radius'
        style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              className=' table-title-bg-7 '
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
            >
              福樹等級
            </th>
            <th
              className=' table-title-bg-7 '
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
            >
              獎勵內容
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級33</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 8★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級32</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 7★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級31</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 6★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級30</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 5★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級29</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 4★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級28</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 3★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級27</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 2★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級26</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>尊榮廳 1★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級25</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 8★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級24</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 7★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級23</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 6★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級22</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 5★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級21</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 4★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級20</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 3★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級19</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 2★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級18</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>貴賓廳 1★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級17</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 8★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級16</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 7★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級15</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 6★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級14</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 5★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級13</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 4★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級12</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 3★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級11</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 2★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級10</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>大眾廳 1★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級9</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 8★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級8</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 7★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級7</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 6★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級6</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 5★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級5</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 4★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級4</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 3★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級3</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 2★ 道具</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>等級2</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>歡樂廳 1★ 道具</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span style={{ color: '#ffde9e' }}>【注意事項】</span>
      </p>
      <ol>
        <li>
          當使用複數個澆水器澆水，會於福樹達到等級33滿等時停止澆水，且將未使用的澆水器換算為等值水滴進行退還。
          <br />
          <span style={{ color: '#888' }}>
            例：使用一次澆水器A需消耗100水滴。小寶花費1,000水滴欲使用澆水器A澆水10次，於澆到第5次時福樹達到滿等。則系統將停止澆水，且退還500水滴。
          </span>
        </li>
        <li>
          活動結束後，活動介面會保留3天，在此期間玩家仍可使用水滴進行澆水與採收獎勵，但遊玩遊戲已無法再獲得水滴。
        </li>
        <li>活動結束3天後，剩餘水滴將全數由系統回收。</li>
        <li>
          如該把遊戲行為結算時，超出活動截止時間，則該把遊戲行為不計入活動。
          起因於連線狀況不穩，導致的結算時間延遲，將以伺服器之電磁紀錄為準進行獎勵結算。
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>官方保有調整或修改活動規則的權益。</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>立即下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
