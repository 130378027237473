import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import TopAwardSingle from '../topAwardSingle/TopAwardSingle'
import TopAwardDouble from '../topAwardDouble/TopAwardDouble'
import TopAwardDate from '../topAwardDate/TopAwardDate'
import { ITopAwardData, ITopAwardDateData } from 'declare/index'
import styles from './topAward.module.sass'

// Gold
import GoldTitle from 'views/activity250113/images/reward/Reward title/gold_award/0_gold_award.png'
import GoldAwardTitle1 from 'views/activity250113/images/reward/Reward title/gold_award/gold_award_1.png'
import GoldAwardTitle2 from 'views/activity250113/images/reward/Reward title/gold_award/gold_award_2.png'
import GoldAwardTitle3 from 'views/activity250113/images/reward/Reward title/gold_award/double_gold_award_1.png'
import GoldAwardTitle4 from 'views/activity250113/images/reward/Reward title/gold_award/double_gold_award_2.png'
import GoldAwardTitle5 from 'views/activity250113/images/reward/Reward title/gold_award/double_gold_award_3.png'
import GoldAwardTitle6 from 'views/activity250113/images/reward/Reward title/gold_award/double_gold_award_4.png'
import GoldAwardImage1 from 'views/activity250113/images/reward/YAMAHA重機卡.png'
import GoldAwardImage2 from 'views/activity250113/images/reward/十萬金牌卡.png'

// Silver
import SilverTitle from 'views/activity250113/images/reward/Reward title/silver_award/0_silver_award.png'
import SilverAwardTitle1 from 'views/activity250113/images/reward/Reward title/silver_award/silver_award_1.png'
import SilverAwardTitle2 from 'views/activity250113/images/reward/Reward title/silver_award/silver_award_2.png'
import SilverAwardTitle3 from 'views/activity250113/images/reward/Reward title/silver_award/double_silver_award_1.png'
import SilverAwardTitle4 from 'views/activity250113/images/reward/Reward title/silver_award/double_silver_award_2.png'
import SilverAwardTitle5 from 'views/activity250113/images/reward/Reward title/silver_award/double_silver_award_3.png'
import SilverAwardTitle6 from 'views/activity250113/images/reward/Reward title/silver_award/double_silver_award_4.png'
import SilverAwardImage1 from 'views/activity250113/images/reward/黃金麻將卡.png'
import SilverAwardImage2 from 'views/activity250113/images/reward/禮金卡_AirPods Pro.png'

// Common
import AwardImagePurple from 'images/award/鴻運卷軸.png'
import AwardImagePurpleEight from 'images/award/貴賓紫卷_8星.png'
import AwardImageDaJungPurpleEight from 'images/award/大眾紫卷_8星.png'
import AwardImageDaJungRedEight from 'images/award/大眾紅卷_8星.png'
import AwardImageBowl from 'images/award/元寶(多).png'
import AwardImageBowlFew from 'images/award/元寶(少).png'
import LiveImg from 'views/activity250113/images/reward/Reward title/draw time.png'
import TopAwardBG01 from 'views/activity230911/images/reward/gold_award_BG_01.png'
import TopAwardMobileBG01 from 'views/activity230911/images/reward/gold_award_BG_mob_01.png'

const styledTopAwardSingle = {
  contentBackgroundColor: '#680400',
  contentBorderColor: '#cb0f3c',
  bannerBorderColor: '#cb0f3c',
  bannerBackgroundColor: '#890800'
}

const goldAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: GoldAwardTitle1,
    awardImg: GoldAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center"><span style="color: #FCF1AA">YAMAHA重機卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  }
]

const goldFiveData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle2,
      awardImg: GoldAwardImage2,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">十萬金牌卡</span><br/>每次 1 位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle3,
      awardImg: AwardImagePurple,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">尊榮廳4★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle4,
      awardImg: AwardImagePurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">貴賓廳8★紫卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle5,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶10000個</span><br/>（每次 2 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: GoldAwardTitle6,
      awardImg: AwardImageBowl,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶3000個</span><br/>（每次 15 位）</p>'
    }
  ]
]

const sliverAwardData: Array<ITopAwardData> = [
  {
    ...styledTopAwardSingle,
    titleImg: SilverAwardTitle1,
    awardImg: SilverAwardImage1,
    content:
      '<p style="font-weight: 800; text-align: center"><span style="color: #FCF1AA">純金麻將卡</span><br class="m:hidden"/> 1位</p>',
    contentIcon: (
      <div className={styles.contentIcon}>
        <img className={styles.contentIcon} src={LiveImg} alt='liveImage' />
      </div>
    ),
    bgDesktopImg: TopAwardBG01,
    bgMobileImg: TopAwardMobileBG01
  }
]

const sliverDoubleData = [
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle2,
      awardImg: SilverAwardImage2,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">AirPods Pro禮金卡</span><br/>每次 1 位</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle3,
      awardImg: AwardImageDaJungPurpleEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紫卷</span><br/>（每次 1 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle4,
      awardImg: AwardImageDaJungRedEight,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">大眾廳8★紅卷</span><br/>（每次 1 位）</p>'
    }
  ],
  [
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle5,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶1000個</span><br/>（每次 2 位）</p>'
    },
    {
      ...styledTopAwardSingle,
      titleImg: SilverAwardTitle6,
      awardImg: AwardImageBowlFew,
      content:
        '<p style="font-weight: 800; text-align: center;"><span style="color: #FCF1AA">元寶300個</span><br/>（每次 85 位）</p>'
    }
  ]
]

const awardDateData: ITopAwardDateData = {
  dateDesktopImg: null,
  dateMobileImg: null,
  bannerBorderColor: '#cb0f3c',
  bannerBackgroundColor: '#890800',
  dateString: '以下於 1/27(一)、2/10(一)進行抽獎'
}

function TopAward() {
  // const [isMobile, setIsMobile] = useState(false)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.matchMedia('(max-width: 980px)').matches)
  //   }

  //   // 初始化設定
  //   handleResize()

  //   // 監聽螢幕變化
  //   window.addEventListener('resize', handleResize)

  //   // 清理事件監聽器
  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  // const goldDataToRender = isMobile ? goldDoubleData : goldTripleData

  // const silverDataToRender = isMobile ? sliverDoubleData : sliverTripleData

  return (
    <section className={styles.root}>
      {/** 金券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={GoldTitle} alt='gold-title' />
        </div>
      </h3>
      {goldAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {goldFiveData.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● YAMAHA卡：價值22,000,000金幣。</span>
        <br />
        <span>● 十萬金牌卡：價值 10,000,000 金幣。</span>
        <div>獎項圖僅供參考，實際發送為遊戲金幣。</div>
      </p>
      <p>&nbsp;</p>
      {/** 銀券獎項 */}
      <h3 className={clsx(styles.title, 'relative')}>
        <div className='image_obj'>
          <img src={SilverTitle} alt='gold-title' />
        </div>
      </h3>
      {sliverAwardData.map((el) => (
        <TopAwardSingle key={el.content} {...el} />
      ))}
      <div>
        <TopAwardDate sx={{ marginBottom: 0 }} {...awardDateData} />
        {sliverDoubleData.map((el) => (
          <TopAwardDouble key={uuid()} data={el} />
        ))}
      </div>
      <p>&nbsp;</p>
      <p>
        <span>● 純金麻將卡：價值2,000,000金幣。</span>
        <br />
        <span>● AirPods Pro禮金卡：價值 750,000 金幣。</span>
        <div>獎項圖僅供參考，實際發送為遊戲金幣。</div>
      </p>
    </section>
  )
}

export default TopAward
