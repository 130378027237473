import ActivityDate from 'views/activity250113/images/title/t1.png'
import ActivityMethod from 'views/activity250113/images/title/t2.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2025/1/13(一) 00:00~2025/2/9(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-award'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-award'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間於刮刮樂遊玩任意刮刮卡，若有刮中獎金皆有高機率獲得隨機乘倍獎勵，
          <span style={{ color: '#f5e61c' }}>最高加碼30%！</span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span>範例：</span>
        <br />
        <span>
          玩家於 超級麻將
          刮中20,000,000金幣，觸發乘倍獎勵+30%，則最終玩家可領取20,000,000+6,000,000=26,000,000金幣。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>官方保有調整或修改活動規則的權益。</p>
      <p>&nbsp;</p>
      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>立即下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
        <br />
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
