import React from 'react'
import ActivityDate from 'views/activity250113/images/title/t1.png'
import ActivityMethod from 'views/activity250113/images/title/t2.png'
import ActivityReward from 'views/activity250113/images/title/t3.png'
import ActivityDesignated from 'views/activity250113/images/title/t4.png'

function Activity() {
  return (
    <>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDate}
          alt='activity-date'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDate}
          alt='activity-date'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>2025/1/27(一) 10:00~2025/2/2(日) 23:59</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityDesignated}
          alt='activity-designated'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>【發發龍】、【龍門天下】、【招財進寶】、【金馬財富】</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityMethod}
          alt='activity-method'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityMethod}
          alt='activity-method'
        />
      </p>
      <p>&nbsp;</p>
      <p>
        <span>
          活動期間遊玩指定遊戲，將於<span style={{ color: '#f5e61c' }}>週日</span>
          24:00後，依照「積分榜-龍榜」指定廳館<span style={{ color: '#f5e61c' }}>累積贏分排名</span>
          結算，於指定名次內贈送百萬獎金！若有同分的狀況，則以時間先後順序排名，獎勵將於結算後5分鐘內發送至信箱。
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <img
          className='m:hidden'
          style={{ width: '303px' }}
          src={ActivityReward}
          alt='activity-reward'
        />
        <img
          className='hidden m:block'
          style={{ width: '240px', margin: '0 auto' }}
          src={ActivityReward}
          alt='activity-reward'
        />
      </p>
      <p>&nbsp;</p>
      <table
        className='table-bg-7 table-radius'
        style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
      >
        <thead>
          <tr>
            <th
              colSpan={3}
              className='table-rounded-tl table-title-bg-7 table-rounded-tr'
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px', color: '#ffde9e' }}
            >
              積分龍榜獎勵
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th
              className='table-title-bg-7'
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px', color: '#ffde9e' }}
            >
              廳館
            </th>
            <th
              className='table-title-bg-7'
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px', color: '#ffde9e' }}
            >
              名次
            </th>
            <th
              className='table-title-bg-7'
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px', color: '#ffde9e' }}
            >
              獎勵
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={3} style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>
              尊榮 貴賓廳
            </td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>3,500,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>1,500,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>500,000 金幣</td>
          </tr>

          <tr>
            <td rowSpan={5} style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>
              大眾廳
            </td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>200,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>100,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>50,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 4 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>30,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 5 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>20,000 金幣</td>
          </tr>
          <tr>
            <td
              rowSpan={4}
              className='table-rounded-bl'
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
            >
              歡樂廳
            </td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 1 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>5,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 2 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>3,000 金幣</td>
          </tr>
          <tr>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>第 3 名</td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>1,500 金幣</td>
          </tr>
          <tr>
            <td
              style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}
              className='table-rounded-br'
            >
              第 4~10 名
            </td>
            <td style={{ border: '1px solid #cb0f3c', borderRadius: '2px' }}>1,000 金幣</td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <span>
          ※ 如遊戲行為結算時，超出活動截止時間，則該把遊戲行為不計入活動。
          起因於連線狀況不穩，導致的結算時間延遲，將以伺服器之電磁紀錄為準進行獎勵結算。
        </span>
      </p>
      <p>&nbsp;</p>

      <p>
        <span>
          <span style={{ color: '#f5e61c' }}>立即下載：</span>
          <a
            href='https://apps.apple.com/tw/app/%E8%81%9A%E5%AF%B6online/id1563757256'
            target='_blank'
            rel='noreferrer'
          >
            點擊前往
          </a>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>聚寶Online祝福您中大獎</p>
    </>
  )
}

export default Activity
