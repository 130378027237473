import React from 'react'
import FullImageM from 'views/activity250113/images/banner/MOB.png'
import FullImageTopL_D from 'views/activity250113/images/banner/05_麻將禮金卡.png'
import FullImageRight from 'views/activity250113/images/banner/04_重機禮金卡.png'
import FullImageRightBottom_Coin from 'views/activity250113/images/banner/03_金幣.png'
import FullImageRightModel from 'views/activity250113/images/banner/02_人.png'
import clsx from 'clsx'
import styles from './header.module.sass'
import useHeaderAnimationToggle from 'hook/useHeaderAnimationToggle'

function Header() {
  const [headerAnimationIsActive] = useHeaderAnimationToggle()

  return (
    <header className={styles.root}>
      <div className={styles.fullImageMobile}>
        <div className={styles.mobileBackground}>
          <img src={FullImageM} alt='full-img-m' />
        </div>
        {/* <div className='image_obj'>
          <img src={TitleM} alt='title-m' />
        </div> */}
      </div>
      <div className={styles.fullImageDesktop}>
        <div className={styles.topL}>
          <div className='image_obj'>
            <img src={FullImageTopL_D} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.rightCard}>
          <div className='image_obj'>
            <img src={FullImageRight} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.rightBottomCoin}>
          <div className='image_obj'>
            <img src={FullImageRightBottom_Coin} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.topR}>
          <div className='image_obj'>
            <img src={FullImageRightModel} alt='full-img-top-d' />
          </div>
        </div>
        <div className={styles.carContainer}>
          <div className={clsx(styles.carAnim, headerAnimationIsActive && styles.isActive)}>
            <div className={styles.car}>
              <div className={styles.star1}></div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}></div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.lightOn}></div>
            <div className={styles.lightOff}></div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
